<template>
  <b-card>
    <div>
      <div class="text-black text-2xl font-semibold">
        Ubah Data Event
      </div>
    </div>
    <div class="divider_solid" />
    <div>
      <b-row>
        <b-col md="8">
          <section class="scroll_event">
            <div class="text-[#333333] text-lg mb-2">
              Informasi Event
            </div>
            <b-form-group
              id="title_event"
              label-cols="4"
              label-cols-lg="3"
              label="*Judul Event"
              label-class="text-md text-[#828282]"
              label-for="title_event"
            >
              <template #label>
                <span class="text-red-500">*</span>Judul Event
              </template>
              <b-form-input
                id="title_event"
                v-model="state.form.event_title"
                type="text"
                :maxlength="30"
                :minlength="3"
                placeholder="Buat judul event"
                :class="error.form.event_title?'border-primary': ''"
                required
              />
              <p
                v-if="error.form.event_title"
                class="text-xs mt-[4px] text-primary"
              >
                {{ error.form.event_title }}
              </p>
            </b-form-group>
            <b-form-group
              id="fieldset-1"
              label-cols="4"
              label-cols-lg="3"
              label="*Sub-Judul Event"
              label-class="text-md text-[#828282]"
              label-for="sub_title_event"
            >
              <template #label>
                <span class="text-red-500">*</span>Sub-Judul Event
              </template>
              <b-form-input
                id="sub_title_event"
                v-model="state.form.event_sub_title"
                rows="3"
                placeholder="Buat sub-judul event"
                :class="error.form.event_sub_title?'border-primary': ''"
              />
              <p
                v-if="error.form.event_sub_title"
                class="text-xs mt-[4px] text-primary"
              >
                {{ error.form.event_sub_title }}
              </p>
            </b-form-group>
            <b-form-group
              id="textarea-rows"
              label-cols="4"
              label-cols-lg="3"
              label="*Deskripsi"
              label-class="text-md text-[#828282]"
              label-for="textarea-rows"
            >
              <template #label>
                <span class="text-red-500">*</span>Deskripsi
              </template>
              <div>
                <b-form-textarea
                  id="textarea-rows"
                  v-model="state.form.description"
                  placeholder="Masukan Deskripsi Event"
                  rows="8"
                  :maxlength="250"
                  :class="error.form.description?'border-primary': ''"
                />
                <span class="text-xs flex justify-content-end"> {{ state.form.description.length }}/250</span>
                <p
                  v-if="error.form.description"
                  class="text-xs  text-primary"
                >
                  {{ error.form.description }}
                </p>
              </div>
            </b-form-group>
            <b-form-group
              id="fieldset-1"
              label-cols="4"
              label-cols-lg="3"
              label="*Tipe Event"
              label-class="text-7 text-[#828282]"
              label-for="type_event"
            >
              <template #label>
                <span class="text-red-500">*</span>Tipe Event
              </template>
              <v-select
                id="type-event"
                v-model="state.form.event_type"
                :options="state.options"
                label="type_event"
                placeholder="Pilih tipe event"
                :class="error.form.event_type?'border-primary': ''"
              />
              <p
                v-if="error.form.event_type"
                class="text-xs mt-[4px] text-primary"
              >
                {{ error.form.event_type }}
              </p>
            </b-form-group>
            <b-form-group
              id="fieldset-1"
              label="*Diselenggarakan"
              label-cols="4"
              label-cols-lg="3"
              class="mt-[2rem]"
              label-class="flex items-center text-md text-[#828282]"
            >
              <template #label>
                <span class="text-red-500">*</span>Diselenggarakan
              </template>
              <b-form-radio-group
                v-model="state.form.event_status"
                :options="state.radio"
                style="accent-color: #F95031; margin-top: 0.6rem; margin-left: 0.6rem;"
                name="radio-inline"
              />
            </b-form-group>
            <div class="divider_line" />
            <div class="text-[#333333] text-lg mb-2">
              Detail Event
            </div>
            <b-form-group
              id="fieldset-1"
              label="*Waktu"
              label-cols="4"
              label-cols-lg="3"
              label-class="text-md text-[#828282]"
              label-for="date"
            >
              <template #label>
                <span class="text-red-500">*</span>Waktu
              </template>
              <date-range-picker
                ref="picker"
                v-model="state.dateRangeActivity"
                :locale-data="state.locale"
                :ranges="false"
                :show-ranges="false"
                class="w-[100%] mt-[1rem]"
                :class="error.form.start_date?'border-primary': ''"
              >
                <template
                  v-slot:input="picker"
                >
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div
                      v-if="state.dateRangeActivity.startDate === null"
                    >
                      Pilih waktu event
                    </div>
                    <div v-else>
                      {{ DAY_MONTH_YEAR( picker.startDate ) }} - {{ DAY_MONTH_YEAR(picker.endDate ) }}
                    </div>
                    <div
                      class="ml-1"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                        alt="calendar"
                        srcset=""
                      >
                    </div>
                  </div>

                </template>
              </date-range-picker>
              <p
                v-if="error.form.event_start_date"
                class="text-xs mt-[2px] text-primary"
              >
                {{ error.form.event_start_date }}
              </p>
            </b-form-group>
            <b-form-group
              id="fieldset-1"
              label="*Lokasi"
              label-cols="4"
              label-cols-lg="3"
              label-class="text-md text-[#828282]"
              label-for="location"
            >
              <template #label>
                <span class="text-red-500">*</span>Lokasi
              </template>
              <b-form-input
                id="location"
                v-model="state.form.event_location"
                :value="state.form.event_status === 'online' ? 'Via Zoom Meeting' : state.form.location"
                type="text"
                :placeholder="state.form.event_status === 'offline'? 'Masukan lokasi event' : 'Masukan Link Webinar'"
                required
                :class="error.form.event_location?'border-primary': ''"
              />
              <p
                v-if="error.form.event_location"
                class="text-xs mt-[4px] text-primary"
              >
                {{ error.form.event_location }}
              </p>
            </b-form-group>
            <b-form-group
              id="fieldset-1"
              label-cols="4"
              label-cols-lg="3"
              label="*Jumlah Peserta"
              label-class="text-md text-[#828282]"
              label-for="max_participants"
              class="mt-[2rem]"
            >
              <template #label>
                <span class="text-red-500">*</span>Jumlah Peserta
              </template>
              <b-form-input
                id="max_participants"
                v-model="state.form.max_attendance"
                type="number"
                placeholder="99"
                :class="error.form.max_attendance?'border-primary': ''"
                required
                @input="maxNumberLength($event, 5)"
                @keypress="isNumber($event)"
              />
              <p
                v-if="error.form.max_attendance"
                class="text-xs mt-[4px] text-primary"
              >
                {{ error.form.max_attendance }}
              </p>
            </b-form-group>
            <b-form-group
              id="fieldset-1"
              label="*Harga"
              label-cols="4"
              label-cols-lg="3"
              label-class="text-md text-[#828282]"
              label-for="price"
              :class="error.form.event_price?'mt-[0.6rem]': 'mt-[2.5rem]'"
            >
              <template #label>
                <span class="text-red-500">*</span>Harga
              </template>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text style="background-color: #F4F4F4; color:#626262">
                    Rp
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="price"
                  v-model="state.form.event_price"
                  type="text"
                  placeholder="0"
                  required
                  :class="error.form.event_price?'border-primary': ''"
                  @input="formatPrice"
                  @keypress="handleInput($event)"
                />
              </b-input-group>
              <p
                v-if="error.form.event_price"
                class="text-xs mt-[4px] text-primary"
              >
                {{ error.form.event_price }}
              </p>
            </b-form-group>
            <b-form-group
              id="link"
              label-cols="4"
              label-cols-lg="3"
              label="Link Landing Page"
              label-class="text-md text-[#828282]"
              label-for="link"
            >
              <b-form-input
                id="link"
                v-model="state.form.link_landingpage"
                type="url"
                placeholder="Masukkan Link Event"
                required
              />
            </b-form-group>
            <div class="divider_line" />
            <div class="text-[#333333] text-lg mb-2">
              Narasumber
            </div>
            <b-row
              v-for="(speaker, index) in state.form.speakers"
              :key="index"
              class="items-center"
            >
              <b-col md="6">
                <b-form-group
                  id="fieldset-1"
                  label=""
                  label-class="text-7 text-[#828282]"
                  label-for="type_event"
                >
                  <v-select
                    id="type-event"
                    :reduce="item => ({ name: item.name, pumkm_id: item.pumkm_id, user_id: item.user_id })"
                    :options="state.optionsSpeaker"
                    label="name"
                    taggable
                    placeholder="Pilih narasumber"
                    :value="speaker.name"
                    @input="updateSpeaker($event, index)"
                    @search="debounceSearch"
                  >
                    <template #option="{ name, pumkm_id }">
                      <div class="d-flex align-items-center">
                        <span class="mr-[5px]">{{ truncateText(name, 23) }}</span>
                        <img
                          v-if="pumkm_id !== 0 && isInList(name)"
                          src="https://storage.googleapis.com/komerce/assets/logo/logo-only/Logo-pumkm.svg"
                          alt="speaker-image"
                          width="18px"
                        >
                      </div>
                    </template>
                    <template #selected-option="{ name, pumkm_id }">
                      <div class="d-flex align-items-center">
                        <span class="mr-[5px]">{{ name }}</span>
                        <img
                          v-if="pumkm_id !== 0 && isInList(name)"
                          src="https://storage.googleapis.com/komerce/assets/logo/logo-only/Logo-pumkm.svg"
                          alt="speaker-image"
                          width="18px"
                        >
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <section class="d-flex gap-[10px] items-center">
                  <b-form-group
                    id="fieldset-1"
                    label=""
                    label-class="text-7 text-[#828282]"
                    label-for="type_event"
                    class="w-100"
                  >
                    <v-select
                      id="type-event"
                      v-model="speaker.position"
                      :options="state.optionsPosision"
                      label="type_event"
                      placeholder="Pilih Posisi"
                    />
                  </b-form-group>
                  <div
                    v-if="state.form.speakers.length > 1"
                    class="button_trash"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                      alt="trash"
                      class="form-group"
                      @click="removeSpeaker(index)"
                    >
                  </div>
                </section>
              </b-col>
            </b-row>
            <div
              v-if="state.form.speakers.length < 10"
              class="button_add_row mb-[15px]"
            >
              <b-button
                variant="outline-primary"
                class="d-flex items-center gap-2"
                @click="addSpeaker"
              >
                <span>+</span>
                <span>Tambah narasumber</span>
              </b-button>
            </div>
          </section>
        </b-col>
        <b-col md="4">
          <div class="text-[#333333] text-lg mb-2">
            Unggah Poster
          </div>
          <div class="preview_image mb-2">
            <section :class="state.form.poster ? 'show_image' : ''">
              <span
                v-if="!state.form.poster"
                class="before_preview"
              >
                <img src="https://storage.googleapis.com/komerce/assets/illustration/Support-sending-images-&-text.svg">
              </span>
              <img
                v-if="state.form.poster"
                :src="state.form.poster"
                :class="state.form.poster ? 'image_preview' : 'image_not_preview'"
                :data-target="state.form.poster ? '#exampleModal' : ''"
                :data-toggle="state.form.poster ? 'modal' : ''"
                alt="Preview Image"
              >
            </section>
          </div>
          <div
            id="exampleModal"
            class="modal fade"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                  <img
                    :src="state.form.poster"
                    alt="Preview Image"
                  >
                </div>
                <div class="modal-footer justify-content-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
          <b-input-group>
            <b-input-group-append
              class="d-flex align-items-center w-100"
              @click="$refs.photoFile.click()"
            >
              <BButton
                class="d-flex align-items-center ml-[4px] w-40 btn-upload"
                size="sm"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-upload.svg"
                  alt=""
                  width="15px"
                >
                <span
                  class="ml-[2px] text-[#F95031]"
                  style="font-size: 12px"
                >
                  Pilih Foto</span>
              </BButton>
              <b-form-input
                v-model="state.photoName"
                class="text-file-foto"
                style="border-left: none;
                      border-bottom-left-radius: 0%;
                      border-top-left-radius: 0%;
                      border: none;
                      background-color: white;"
                :readonly="true"
                :placeholder="state.photoName? state.photoName : state.originalPoster"
              />
            </b-input-group-append>
            <input
              id="poster"
              ref="photoFile"
              class="form-control"
              type="file"
              style="display: none"
              @change="validateExt"
            >
          </b-input-group>
          <div
            :class="error.form.poster?'text-primary': ''"
            class="text-xs mt-[4px]"
          >
            *Upload foto dengan maksimal 1 Mb ukuran 300 x 300px
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="divider_solid" />
    <BRow>
      <BCol>
        <div
          class="d-flex justify-end "
          style="gap: 1rem;"
        >
          <b-button
            variant="outline-primary"
            @click="handleBack"
          >
            Kembali
          </b-button>
          <b-button
            v-b-modal.modal-add-event
            :variant="event.hasEditEvent ? 'primary' : 'secondary'"
            type="submit"
            :disabled="event.hasEditEvent ? event.submit : !event.hasEditEvent"
            @click="handleSubmit"
          >
            Simpan
          </b-button>
        </div>
      </BCol>
    </BRow>
  </b-card>

</template>

<script>
import {
  reactive, ref, onMounted, computed, watchEffect,
} from '@vue/composition-api'
import { newAxiosIns } from '@/libs/axios'
import vSelect from 'vue-select'
import router from '@/router'
import moment from 'moment'
import { isNumber } from '@/libs/helpers'
import { useRouter } from '@/@core/utils/utils'
import dateRangePicker from 'vue2-daterange-picker'
import {
  today,
  last7,
  last2,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
} from '@/store/helpers'
import { YEAR_MONTH_DAY, DAY_MONTH_YEAR } from '@/libs/filterDate'
import { toastSuccess, toastError } from '../config'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    vSelect,
    dateRangePicker,
  },

  setup() {
    const photoUpload = ref({})
    const route = useRouter()
    const error = reactive({
      form: {
        event_title: '',
        event_sub_title: '',
        event_type: '',
        event_status: '',
        event_price: '',
        event_location: '',
        max_attendance: '',
        event_start_date: '',
        event_end_date: '',
        poster: '',
        description: '',
        link_landingpage: '',
      },
    })
    const event = reactive({
      data: {},
      dataSpeaker: [],
      submit: true,
      hasEditEvent: false,
    })
    const picker = ref({
      startDate: firstDateOfMonth,
      endDate: lastDateOfMonth,
    })

    const state = reactive({
      photoName: '',
      originalPoster: null,
      disabledSubmit: true,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      dateRangeUpdate: {
        startDate: today,
        endDate: today,
      },
      dateRangeActivity: {
        startDate: '',
        endDate: '',
      },
      data: {},
      form: {
        event_title: '',
        event_sub_title: '',
        event_type: '',
        event_status: '',
        event_price: '',
        event_location: '',
        max_attendance: '',
        event_start_date: '',
        event_end_date: '',
        poster: '',
        description: '',
        link_landingpage: '',
        speakers: [
          {
            user_id: 0,
            pumkm_id: 0,
            name: null,
            position: null,
          },
        ],
      },
      previewImage: null,
      radio: [
        { text: 'Online', value: 'online' },
        { text: 'Offline', value: 'offline' },
      ],
      options:
      ['Bootcamp', 'Gathering', 'Kopdar Komunitas', 'Webinar', 'Roadshow', 'Seminar', 'Workshop'],
      optionsPosision: ['Speaker', 'Trainer', 'Fasilitator'],
      optionsSpeaker: [],
      selectedSpeaker: [],
      selectedPosition: [],
      search: '',
      resultSearch: false,
      debounceSearch: null,
    })

    const formatPriceGet = inputValue => {
      const regex = /\B(?=(\d{3})+(?!\d))/g
      const formattedValue = inputValue.replace(regex, '.')
      return formattedValue
    }

    async function getListData() {
      const idEvent = route.route.value.query.id
      const url = `/komclass/api/v1/event/${idEvent}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          const price = formatPriceGet(data.event_detail.event_price.toString())
          event.data = data.event_detail
          state.form.event_type = data.event_detail.event_type
          state.form.event_title = data.event_detail.event_title
          state.form.event_sub_title = data.event_detail.event_sub_title
          state.form.event_status = data.event_detail.event_status
          state.form.max_attendance = data.event_detail.max_attendance
          state.form.event_price = price
          state.form.event_location = data.event_detail.event_location
          state.dateRangeActivity.startDate = moment(data.event_detail.event_date_start)
          state.dateRangeActivity.endDate = moment(data.event_detail.event_date_end)
          state.form.description = data.event_detail.description
          state.form.poster = data.event_detail.poster
          state.originalPoster = data.event_detail.poster
          state.form.link_landingpage = data.event_detail.link_landingpage
          const selectedData = data.performers
            .filter(speaker => speaker.name !== null && speaker.position !== null)
            .map(speaker => ({
              user_id: speaker.user_id === 0 && typeof speaker.name === 'object' ? speaker.name.user_id : speaker.user_id,
              pumkm_id: speaker.pumkm_id === 0 && typeof speaker.name === 'object' ? speaker.name.pumkm_id : speaker.pumkm_id,
              name: typeof speaker.name === 'object' ? speaker.name.name : speaker.name,
              position: speaker.position,
            }))
          event.dataSpeaker = selectedData
          state.form.speakers = data.performers === null ? [{
            name: null, position: null, pumkm_id: 0, user_id: 0,
          }] : data.performers
        })

        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }

    function handleConfirmSubmit() {
      const url = `/komclass/api/v1/event/${route.route.value.query.id}`
      newAxiosIns.put(url, state.data)
        .then(res => {
          const { data } = res.data
          router.push({
            name: 'data-event',
          })
          toastSuccess('Berhasil mengubah event')
        })
        .catch(err => {
          toastError('Ada kesalahan saat mengubah event')
        })
    }

    function handleSubmit() {
      const selectedData = state.form.speakers
        .filter(speaker => speaker.name !== null && speaker.position !== null)
        .map(speaker => ({
          user_id: speaker.user_id,
          pumkm_id: speaker.pumkm_id,
          name: speaker.name,
          position: speaker.position,
        }))

      if (selectedData.length === 0) {
        state.form.speakers = [{
          name: null, position: null, pumkm_id: 0, user_id: 0,
        }]
      }
      const price = state.form.event_price.split('.').join('')
      const formData = new FormData()
      formData.append('event_type', state.form.event_type)
      formData.append('event_title', state.form.event_title)
      formData.append('event_sub_title', state.form.event_sub_title)
      formData.append('event_status', state.form.event_status)
      formData.append('event_price', parseInt(price, 10))
      formData.append('event_location', state.form.event_location)
      formData.append('max_attendance', parseInt(state.form.max_attendance, 10))
      formData.append('event_start_date', YEAR_MONTH_DAY(state.dateRangeActivity.startDate))
      formData.append('event_end_date', YEAR_MONTH_DAY(state.dateRangeActivity.endDate))
      formData.append('poster', state.form.poster === event.data.poster ? '' : photoUpload.value)
      formData.append('description', state.form.description)
      formData.append('link_landingpage', state.form.link_landingpage)
      formData.append('event_performers', JSON.stringify(selectedData))

      state.data = formData
      handleConfirmSubmit()
    }

    function handleBack() {
      router.back()
    }

    const updateSpeaker = (selectedValue, index) => {
      if (selectedValue) {
        state.form.speakers[index].name = selectedValue.name
        state.form.speakers[index].pumkm_id = selectedValue.pumkm_id
        state.form.speakers[index].user_id = selectedValue.user_id
      }
    }

    const checkDataChanged = () => {
      const selectedData = state.form.speakers
        .filter(speaker => speaker.name !== null && speaker.position !== null)
        .map(speaker => ({
          user_id: speaker.user_id === 0 && typeof speaker.name === 'object' ? speaker.name.user_id : speaker.user_id,
          pumkm_id: speaker.pumkm_id === 0 && typeof speaker.name === 'object' ? speaker.name.pumkm_id : speaker.pumkm_id,
          name: typeof speaker.name === 'object' ? speaker.name.name : speaker.name,
          position: speaker.position,
        }))
      event.hasEditEvent = state.form.event_type !== event.data.event_type
      || state.form.event_title !== event.data.event_title
      || state.form.event_sub_title !== event.data.event_sub_title
      || state.form.event_status !== event.data.event_status
      || parseInt(state.form.max_attendance, 10) !== parseInt(event.data.max_attendance, 10)
      || parseInt(state.form.event_price.split('.').join(''), 10) !== parseInt(event.data.event_price, 10)
      || state.form.event_location !== event.data.event_location
      || DAY_MONTH_YEAR(state.dateRangeActivity.startDate) !== DAY_MONTH_YEAR(event.data.event_date_start)
      || DAY_MONTH_YEAR(state.dateRangeActivity.endDate) !== DAY_MONTH_YEAR(event.data.event_date_end)
      || state.form.description !== event.data.description
      || state.form.link_landingpage !== event.data.link_landingpage
      || state.form.poster !== event.data.poster
      || selectedData.length !== event.dataSpeaker.length
      || JSON.stringify(selectedData) !== JSON.stringify(event.dataSpeaker)
    }

    const isFormValid = () => {
      event.submit = !state.form.event_type
              || state.form.event_title.length >= 50
              || state.form.event_title.length <= 3
              || state.form.event_sub_title.length >= 200
              || state.form.event_sub_title === ''
              || state.form.poster === ''
              || state.form.max_attendance === ''
              || state.form.event_price === ''
              || state.form.event_location.length >= 256
              || state.form.event_location === ''
              || state.form.event_status === ''
              || state.dateRangeActivity.startDate === null
              || state.dateRangeActivity.endDate === null
              || state.form.event_sub_title === ''
              || state.form.description === ''
    }

    const isEmpty = () => {
      switch (true) {
        case !state.form.event_type:
          error.form.event_type = 'Masukkan Tipe Event'
          break
        case state.form.event_title === '':
          error.form.event_title = 'Masukkan Judul Event'
          break
        case state.form.event_sub_title === '':
          error.form.event_sub_title = 'Masukkan Sub-Judul Event'
          break
        case state.form.event_price === '':
          error.form.event_price = 'Masukkan Harga Event'
          break
        case state.form.event_location === '':
          error.form.event_location = 'Masukkan Lokasi Event'
          break
        case state.form.max_attendance === '':
          error.form.max_attendance = 'Masukkan Jumlah Peserta'
          break
        case state.form.description === '':
          error.form.description = 'Masukkan Deskripsi Event'
          break
        case state.dateRangeActivity.startDate === null:
          error.form.event_start_date = 'Masukkan Waktu Event'
          break
        default:
          error.form.event_title = ''
          error.form.event_type = ''
          error.form.event_sub_title = ''
          error.form.event_price = ''
          error.form.event_location = ''
          error.form.description = ''
          error.form.event_start_date = ''
          break
      }
    }

    const validateSize = evChange => {
      const fileUpload = evChange.target
      const file = fileUpload.files[0]
      state.form.poster = URL.createObjectURL(file)

      if (file) {
        const maxSize = 1024 * 1024
        if (file.size >= maxSize) {
          toastError('Ukuran file terlalu besar. Maksimal 1 MB.')
          photoUpload.value = ''
          state.photoName = ''
          state.form.poster = state.originalPoster
        } else {
          photoUpload.value = file
          const objectURL = URL.createObjectURL(file)
          state.form.poster = objectURL
        }
      }
    }
    const validateExt = evChange => {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
        && fileExtension !== 'gif'
      ) {
        toastError('Hanya dapat upload file dengan ekstensi JPG dan PNG')
        state.photoName = ''
        state.form.poster = state.originalPoster
      } else {
        state.photoName = evChange.target.files[0].name
        validateSize(evChange)
      }
    }

    function addSpeaker() {
      state.form.speakers.push({
        user_id: 0,
        pumkm_id: 0,
        name: null,
        position: null,
      })
    }
    function removeSpeaker(index) {
      state.form.speakers.splice(index, 1)
    }
    function truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text
      }
      return `${text.slice(0, maxLength)}...`
    }
    function isInList(fullName) {
      return state.optionsSpeaker.some(option => option.name === fullName)
    }
    function getDataSpeaker() {
      const url = '/komclass/api/v1/event/possible-performers'
      const params = {
        search: state.search,
      }
      newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          state.optionsSpeaker = data
        })
        .catch(err => {
          toastError('Ada kesalahan saat menampilkan data')
        })
    }
    function handleEmptySearch() {
      if (state.search === '') {
        state.resultSearch = false
      }
    }

    async function handleSearch(value) {
      if (!value.length) {
        state.search = ''
        getDataSpeaker()
      } else if (value.length > 2) {
        state.search = value
        getDataSpeaker()
      }
    }
    function debounceSearch(search) {
      if (!search) {
        state.resultSearch = false
      }
      if (state.debounceSearch) {
        clearTimeout(state.debounceSearch)
      }
      state.debounceSearch = setTimeout(() => {
        handleSearch(search)
      }, 1000)
    }

    const handleInput = input => {
      const allowedChars = /[0-9\b]/

      if (allowedChars.test(input.key)) {
        const inputValue = input.target.value.replace(/[^\d]/g, '') // Hapus karakter selain digit
        state.form.event_price = inputValue
      } else {
        input.preventDefault()
      }
    }

    const formatPrice = () => {
      const inputValue = state.form.event_price
      const regex = /\B(?=(\d{3})+(?!\d))/g
      const formattedValue = inputValue.replace(regex, '.')
      state.form.event_price = formattedValue
    }

    watchEffect(() => {
      checkDataChanged()
      isFormValid()
      isEmpty()
    })

    onMounted(() => {
      getListData()
      getDataSpeaker()
      handleEmptySearch()
    })
    computed(() => {
      event.submit = false
    })

    return {
      route,
      picker,
      event,
      router,
      state,
      getListData,
      handleConfirmSubmit,
      handleSubmit,
      handleBack,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      error,
      validateSize,
      validateExt,
      photoUpload,
      handleInput,
      formatPrice,
      formatPriceGet,
      addSpeaker,
      removeSpeaker,
      isInList,
      debounceSearch,
      handleSearch,
      truncateText,
      updateSpeaker,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.divider_line {
  border-top: 1px dashed #E2E2E2;
  margin: 18px 0;
}
.divider_solid {
  border-top: 1px solid #E2E2E2;
  margin: 18px 0;
}
#type-event {
  border-radius: 6px;
}
.before_preview {
  opacity: 0.3;
}
.preview_image {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  border: 1px solid #E2E2E2;
  background-color: #F8F8F8;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.preview_image .image_preview {
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  height: 300px;
  object-fit: cover;
  object-position: center;
}
.preview_image .show_image {
  width: -webkit-fill-available;
}

.preview_image .image_preview:hover {
  opacity: 0.7;
}

button.btn-upload {
  border: 1px solid #F95031 !important;
  background-color: #ffffff !important;
  border-radius: 4px !important;
  padding: 4px 8px;
}
.input-group-append {
  border: 1px dashed #E2E2E2;
  padding: 8px 14px;
  border-radius: 8px;
}
.scroll_event {
  height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 20px;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #F4F4F4;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #828282;
  border-radius: 30px;
}
</style>
